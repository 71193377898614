import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { User } from '../models/user';
import { StoreCategory } from '../models/store-category';
import { StorageService } from './storage.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { Store, StoreItem } from '../models/store';
import { Order } from '../models/order';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class StoreService {
  isLoggedIn = false;
  token:any;

  storeCategories: StoreCategory[] = [];

  adminStores: Store[];
  // storeCategories: StoreCategory[];

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private authService: AuthService,
    private env: EnvService,
  ) { }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


  addStoreCategory(formData: FormData) {
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
    });

    return this.http.post(this.env.API_URL + 'store-categories',formData,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        return data;
      })
    );

  }

  updateStoreCategory(id,formData: FormData) {
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
    });

    return this.http.post(this.env.API_URL + 'store-categories/' + id,formData,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        return data;
      })
    );

  }

  getStoreCategory(storeCategoryID) {
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
    });

    return this.http.get(this.env.API_URL + 'store-categories/' + storeCategoryID,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        return data;
      })
    );
    
  }

  getStoreCategories(optionalParameter = ''): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<any>(this.env.API_URL + 'store-categories' + optionalParameter,{ headers: headers })
      .pipe(
        tap(response => {
          this.storeCategories = response.data;
        }),
        retry(2),
        catchError(this.handleError)
      )
  }


  getStores(): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<any>(this.env.API_URL + 'stores',{ headers: headers })
      .pipe(
        tap(response => {
          this.adminStores = response.data;
          console.log(this.adminStores);
        }),
        retry(2),
        catchError(this.handleError)
      )
  }

  getStoresByItemName(itemName): Observable<any> {

    // const headers = new HttpHeaders({
    //   'Authorization': "Bearer " + this.authService.token
    // });

    return this.http.get<any>(this.env.API_URL + 'search-stores?item_name=' + itemName + '&exclude_items=true',{})
      .pipe(
        tap(response => {
          // this.adminStores = response.data;
          // console.log(this.adminStores);
        }),
        retry(2),
        catchError(this.handleError)
      )
  }
  getStoreItemsByStoreItemName(itemName, storeID): Observable<any> {


    return this.http.get<any>(this.env.API_URL + 'search-store-items?item_name=' + itemName + '&store_id=' + storeID,{})
      .pipe(
        tap(response => {
          // this.adminStores = response.data;
          // console.log(this.adminStores);
        }),
        retry(2),
        catchError(this.handleError)
      )
  }

  getSpecialDeals(): Observable<any> {


    return this.http.get<any>(this.env.API_URL + 'special-deals',{})
      .pipe(
        tap(response => {
          // this.adminStores = response.data;
          // console.log(this.adminStores);
        }),
        retry(2),
        catchError(this.handleError)
      )
  }
  getSpecialDealsByStore(storeID): Observable<any> {


    return this.http.get<any>(this.env.API_URL + 'special-deals?store_id=' + storeID,{})
      .pipe(
        tap(response => {
          // this.adminStores = response.data;
          // console.log(this.adminStores);
        }),
        retry(2),
        catchError(this.handleError)
      )
  }


  getStoresByCategory(id, latitude = '', longitude = ''): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    let endpoint = this.env.API_URL + 'stores/category/' + id;
    
    if (this.authService.isLoggedIn){
      endpoint = this.env.API_URL + 'secured/stores/category/' + id;
    }else{

      if (localStorage.getItem('latitude') && localStorage.getItem('longitude')){
        endpoint = this.env.API_URL + 'stores/category/' + id + '?lat=' + localStorage.getItem('latitude') + '&lng=' + localStorage.getItem('longitude');
      }

    }

    return this.http.get<any>(endpoint,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  getStore($id): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    let endpoint = this.env.API_URL + 'stores/' + $id;
    
    if (this.authService.isLoggedIn){
      endpoint = this.env.API_URL + 'secured/stores/' + $id;
    }


    return this.http.get<any>(endpoint,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }


  updateStore(storeID,formData: FormData) {

    console.log('updateStore formData',formData);
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
      // 'Content-Type': 'multipart/form-data;charset=utf-8'
    });

    return this.http.post(this.env.API_URL + 'stores/update/' + storeID,formData,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        this.getStores().subscribe();
        return data;
      })
    )
  }

  updateStoreLocation(storeID,payload) {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
    });

    return this.http.post(this.env.API_URL + 'stores/update-location/' + storeID,payload,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        this.getStores().subscribe();
        return data;
      })
    )
  }

  
  partnership(payload: NgForm) {
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.post(this.env.API_URL + 'partnership',payload.value,{ headers: headers }).pipe(
      tap(data => {
        console.log('partnership',data);
        // this.storageService.remove("token");
        // this.isLoggedIn = false;
        // delete this.token;
        return data;
      })
    )
  }



  addStoreItem(storeID,formData: FormData) {

    console.log('addStoreItem formData',formData);
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
    });

    return this.http.post(this.env.API_URL + 'store-items',formData,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        return data;
      })
    )
  }
  updateStoreItem(storeItemID,formData: FormData) {

    console.log('addStoreItem formData',formData);
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
    });

    return this.http.post(this.env.API_URL + 'store-items/' + storeItemID,formData,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        return data;
      })
    )
  }
  deleteStoreItem(storeItemID) {

    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
    });

    return this.http.delete(this.env.API_URL + 'store-items/' + storeItemID,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        return data;
      })
    )
  }

  getStoreItems(storeID): Observable<StoreItem[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<StoreItem[]>(this.env.API_URL + 'store-items?store_id=' + storeID,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  getStoreItem(storeItemID): Observable<StoreItem> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<StoreItem>(this.env.API_URL + 'store-items/' + storeItemID,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }


  getStoreOrdersSummary(storeID): Observable<any[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<any[]>(this.env.API_URL + 'orders/' + storeID + '/summary',{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }


  
  getStoreOrdersByStatus(storeID,status): Observable<Order[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<Order[]>(this.env.API_URL + 'orders/' + storeID + '/status/' + status,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  getStoreOrder(storeOrderID): Observable<Order> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<Order>(this.env.API_URL + 'orders/' + storeOrderID + status,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  updateStoreOrderStatus(storeOrderID, column, columnStatus, formData = null): Observable<Order> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.post<Order>(this.env.API_URL + 'orders/' + storeOrderID + '/update-status/' + column + '/' + columnStatus,formData, { headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  

  // Orders
  getOrdersOverallSummary(): Observable<any[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<any[]>(this.env.API_URL + 'orders/overall-summary',{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  getOrdersByStatus(status): Observable<Order[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<Order[]>(this.env.API_URL + 'orders/status/' + status,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }


  getStoreOrdersCompleted(storeID = -1, startDate = null, endDate = null): Observable<Order[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    let param = '?';
    if (startDate){
      param+='start_date=' + startDate;
      param+='&end_date=' + endDate;
    }

    return this.http.get<Order[]>(this.env.API_URL + 'completed-orders/' + storeID + param,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  updateStoreItemMeta(formData: {}) {

    console.log('updateStore formData',formData);
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
      // 'Content-Type': 'multipart/form-data;charset=utf-8'
    });

    return this.http.post(this.env.API_URL + 'store-item-meta-update',formData,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        return data;
      })
    )
  }

}